import React, { useState, useEffect } from 'react'
import { Clock } from 'lucide-react'
import Parser from 'rss-parser'

interface WeatherData {
  temperature: number
  feelsLike: number
  condition: string
  windSpeed: number
  windDirection: string
  humidity: number
  uv: number
  icon: string
}

interface Joke {
  setup?: string;
  delivery?: string;
  joke?: string;
}

interface ForecastDay {
  date: string
  maxTemp: number
  minTemp: number
  condition: string
  icon: string
}

interface NewsItem {
  title: string;
  description: string;
}

export default function WeatherDashboard() {
  const [currentTime, setCurrentTime] = useState(new Date())
  const [weather, setWeather] = useState<WeatherData | null>(null)
  const [news, setNews] = useState<NewsItem | null>(null)
  const [joke, setJoke] = useState<Joke | null>(null)
  const [forecast, setForecast] = useState<ForecastDay[]>([])
  const [backgroundImage, setBackgroundImage] = useState<string | null>(null)

  useEffect(() => {
    const timer = setInterval(() => setCurrentTime(new Date()), 1000)

    // Fetch initial data
    fetchWeather()
    fetchNews()
    fetchJoke()
    fetchBackgroundImage()

    // Set up hourly fetches
    const hourlyFetch = setInterval(() => {
      fetchWeather()
      fetchJoke()
      fetchBackgroundImage()
    }, 3600000) // 3600000 ms = 1 hour

    // Fetch news every 15 minutes
    const newsTimer = setInterval(fetchNews, 900000)

    return () => {
      clearInterval(timer)
      clearInterval(hourlyFetch)
      clearInterval(newsTimer)
    }
  }, [])

  const fetchBackgroundImage = async () => {
    try {
      const hour = new Date().getHours();
      const query = hour < 6 || hour >= 18 ? 'night' : 'day';
      
      console.log('Fetching background image for query:', query);
      const response = await fetch(`/api/pexels?query=${query}&timestamp=${Date.now()}`);

      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(`HTTP error! status: ${response.status}, body: ${errorText}`);
      }

      const data = await response.json();
      console.log('Pexels API response:', data);
      if (data.photos && data.photos.length > 0) {
        const imageUrl = data.photos[0].src.original;
        setBackgroundImage(imageUrl);
        console.log('Set background image to:', imageUrl);
      } else {
        console.log('No photos found in Pexels API response');
      }
    } catch (error) {
      console.error('Error fetching background image:', error);
    }
  };

  const fetchWeather = async () => {
    try {
      const apiKey = process.env.REACT_APP_WEATHER_API_KEY;
      const response = await fetch(`https://api.weatherapi.com/v1/forecast.json?key=${apiKey}&q=auto:ip&days=3&aqi=no&alerts=no`);
      
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      
      const data = await response.json();
      
      setWeather({
        temperature: data.current.temp_c,
        feelsLike: data.current.feelslike_c,
        condition: data.current.condition.text,
        windSpeed: data.current.wind_kph,
        windDirection: data.current.wind_dir,
        humidity: data.current.humidity,
        uv: data.current.uv,
        icon: data.current.condition.icon
      });

      setForecast(data.forecast.forecastday.map((day: any) => ({
        date: new Date(day.date).toLocaleDateString('en-US', { weekday: 'short' }),
        maxTemp: day.day.maxtemp_c,
        minTemp: day.day.mintemp_c,
        condition: day.day.condition.text,
        icon: day.day.condition.icon
      })));
    } catch (error) {
      console.error('Error fetching weather:', error);
      setWeather(null);
      setForecast([]);
    }
  };

  const fetchNews = async () => {
    try {
      const parser = new Parser();
      const feed = await parser.parseURL('https://rss.nytimes.com/services/xml/rss/nyt/World.xml');
      
      if (feed.items && feed.items.length > 0) {
        const latestArticle = feed.items[0];
        setNews({
          title: latestArticle.title || 'No title available',
          description: latestArticle.contentSnippet || 'No description available'
        });
      } else {
        throw new Error('No news articles found in the feed');
      }
    } catch (error: unknown) {
      console.error('Error fetching news:', error);
      setNews({
        title: "Error fetching news",
        description: error instanceof Error ? error.message : "Please try again later."
      });
    }
  };

  const fetchJoke = async () => {
    try {
      const response = await fetch('https://v2.jokeapi.dev/joke/Any?blacklistFlags=nsfw,religious,political,racist,sexist,explicit')
      const data = await response.json()
      setJoke(data)
    } catch (error) {
      console.error('Error fetching joke:', error)
    }
  }

  const formatDate = (date: Date) => {
    return date.toLocaleDateString('en-US', { weekday: 'long', month: 'short', day: 'numeric' })
  }

  return (
    <div className="h-screen w-full bg-black p-4 flex items-center justify-center">
      <div 
        className="w-full h-full bg-cover bg-center rounded-lg overflow-hidden relative"
        style={{ backgroundImage: backgroundImage ? `url(${backgroundImage})` : 'none' }}
      >
        {backgroundImage && <div className="absolute inset-0 bg-black bg-opacity-50"></div>}
        <div className="relative z-10 h-full p-4 text-white flex flex-col">
          <div className="flex justify-between items-start mb-4">
            <div>
              <h1 className="text-3xl font-bold mb-2">{formatDate(currentTime)}</h1>
              <div className="flex items-center">
                <Clock className="w-12 h-12 mr-2" />
                <span className="text-4xl">{currentTime.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit' })}</span>
              </div>
            </div>
          </div>
          
          <div className="flex-grow flex flex-col justify-between">
            {news && (
              <div className="mb-4">
                <h2 className="text-2xl font-bold mb-2">{news.title}</h2>
                <p className="text-sm">{news.description}</p>
              </div>
            )}
            
            <div className="flex justify-between items-end">
              {weather && (
                <div className="flex items-center">
                  <div>
                    <h2 className="text-5xl font-bold">{weather.temperature}°C</h2>
                    <p className="text-lg">{weather.condition}</p>
                    <p className="text-sm">Feels like: {weather.feelsLike}°C</p>
                    <p className="text-sm">Wind: {weather.windSpeed} km/h {weather.windDirection}</p>
                    <p className="text-sm">Humidity: {weather.humidity}%</p>
                    <p className="text-sm">UV Index: {weather.uv}</p>
                  </div>
                  <img src={weather.icon} alt={weather.condition} className="w-20 h-20 ml-4" />
                </div>
              )}
              
              {forecast.length > 0 && (
                <div className="flex">
                  {forecast.map((day, index) => (
                    <div key={index} className="text-center mx-2">
                      <p className="font-semibold">{day.date}</p>
                      <img src={day.icon} alt={day.condition} className="w-10 h-10 mx-auto my-1" />
                      <p className="text-sm">{day.maxTemp}°C / {day.minTemp}°C</p>
                      <p className="text-xs">{day.condition}</p>
                    </div>
                  ))}
                </div>
              )}
            </div>
            
            {joke && (
              <div className="mt-4 p-2 bg-black bg-opacity-50 rounded-lg">
                <h2 className="text-xl font-bold mb-1">Joke of the Hour</h2>
                {joke.setup ? (
                  <>
                    <p className="text-sm mb-1">{joke.setup}</p>
                    <p className="text-sm font-bold">{joke.delivery}</p>
                  </>
                ) : (
                  <p className="text-sm">{joke.joke}</p>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}